import React from "react";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../utils/components/Loading";
import {Button, Form, Table, TextArea} from "semantic-ui-react";
import {MyInput} from "../../../utils/components/MyInput";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import Utils from "../../../utils/utils/Utils";
import moment from "moment";
import {MatchedUser} from "../../../nav/SideBar";

interface ExamResult {
    examination_id: number
    bill_status: "Pending" | "Authorized" | "Cancelled"
    diagnosis_name: string
    remarks: string
    time_examined: string
    doctor: string
    time_created: string
    client: string
    passport: string
}

export default function DiagnosisResults(params: { matchedUser: MatchedUser }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [search, setSearch] = React.useState({search_name: "", min_date: Utils.today(), max_date: Utils.today()})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const initialResults: ExamResult = {
        bill_status: 'Pending', client: "", diagnosis_name: "", doctor: "", examination_id: 0,
        passport   : "", remarks: "", time_created: "", time_examined: ""
    }

    const [examResults, setExamResults] = React.useState(Array<ExamResult>())
    const [examResult, setExamResult] = React.useState(initialResults)

    const save_examination = () => {
        setLoading({message: 'Saving examination, please wait', show: true})
        if (examResult.remarks.trim().length < 10) {
            ToastsStore.error("Enter valid examination results")
        } else {
            ApiRequest.update_diagnosis_results({examination_id: examResult.examination_id, remarks: examResult.remarks})
                .then((response) => {
                    setLoading({message: '', show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setExamResults(examResults.map((aResult) =>
                            aResult.examination_id !== examResult.examination_id ? aResult :
                                {...aResult, time_examined: response.data['time_examined'], doctor: response.data['doctor']}
                        ))
                        ToastsStore.success("Results saved successfully")
                    } else {
                        ToastsStore.error("Could not save results")
                    }
                })
                .catch(() => {
                    setLoading({message: '', show: false})
                    ToastsStore.error("Could not save results")
                })
        }
    }

    const search_results = (client_id?: number) => {
        setLoading({message: 'Loading orders, please wait', show: true})
        ApiRequest.get_diagnosis_orders({max_date: search.max_date, min_date: search.min_date, name: search.search_name.trim(), client_id: client_id})
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setExamResults(response.data['results'])
                } else {
                    ToastsStore.error("Could not diagnosis orders")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not diagnosis orders")
            })
    }

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            search_results(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Client name or email address" name='search_name' value={search.search_name}
                            change={handle_search} enter={search_results}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Orders" onClick={() => search_results()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0">
                    <div className="col-md-9 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center"></Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '130px'}}>Client Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '130px'}}>Doctor Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '170px'}}>Diagnosis Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}}>Bill Status</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>Time Created</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>Time Examined</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        examResults.map((aResult, index) =>
                                            <Table.Row key={aResult.examination_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">
                                                    <Button
                                                        primary size='mini' icon='edit' compact disabled={aResult.bill_status !== 'Authorized'}
                                                        onClick={() => setExamResult(aResult)}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">{index + 1}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{aResult.client}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{aResult.doctor}</Table.Cell>
                                                <Table.Cell style={{width: '170px'}}>{aResult.diagnosis_name}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}}>{aResult.bill_status}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{moment(aResult.time_created).format("lll")}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>
                                                    {aResult.time_examined !== '' && moment(aResult.time_examined).format('lll')}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-md-3 p-1 h-100">
                        <div className="window_content">
                            <div className="form_container">
                                {
                                    examResult.examination_id !== 0 &&
                                    <>
                                        <span className="label mt-0">Client Name</span>
                                        <span className="label_text">{examResult.client}</span>

                                        <span className="label">Examination Name</span>
                                        <span className="label_text">{examResult.diagnosis_name}</span>

                                        <span className="label">Time Ordered</span>
                                        <span className="label_text">{moment(examResult.time_created).format("ll")}</span>
                                    </>
                                }

                                {/*Test Description*/}
                                <Form className="mt-3">
                                    <span className="label">Examination Findings</span>
                                    <TextArea
                                        placeholder='Enter remarks of examination' value={examResult.remarks} rows={25}
                                        onChange={(event, data) => setExamResult({...examResult, remarks: data.value as string})}/>
                                </Form>
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-6 pl-0 pr-1">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                disabled={examResult.examination_id === 0}
                                                content="Clear Data" onClick={() => setExamResult(initialResults)}/>
                                    </div>
                                    <div className="col-6 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid content="Save Results" onClick={save_examination}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
